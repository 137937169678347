import { request } from "./axios_helper"
import { PASSWORD_RESET_API_URL, PROFILE_API_URL } from "../common/constants"
import axios from "axios"
export const getProfileData = async () => {
  try {
    const response = await request().get(PROFILE_API_URL)
    return response.data
  } catch (err) {}
}

export const editProfileData = (data, dispatch, cb) => {
  return request().edit(PROFILE_API_URL, data, dispatch, "patch", cb)
}

export const passwordResetReq = (email) => {
  return axios.post(PASSWORD_RESET_API_URL, { email })
}

export const loadImage = (uri) => {
  return request().get(uri)
}

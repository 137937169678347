import React, { useEffect, useState } from 'react';
import { loadImage } from '../api/user';


const SecureImage = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const uri = 'https://app.qa.savvyy.com/api/file/683/'


                const blob = await loadImage(uri);
                const url = URL.createObjectURL(blob);
                setImageSrc(url);
            } catch (err) {
                console.error('Error fetching the image:', err);
                setError(err);
            }
        };

        fetchImage();

        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [imageSrc]);

    if (error) {
        return <p>Error loading image: {error.message}</p>;
    }

    return (
        <div>
            {imageSrc ? (
                <img src={imageSrc} alt="Secure " />
            ) : (
                <p>Loading image...</p>
            )}
        </div>
    );
};

export default SecureImage;
